@charset "UTF-8";
/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.tenant-wbw .c-tabs-filter-listing {
  margin-bottom: 30px;
}
.tenant-wbw .c-tabs-filter-listing .w--content {
  max-width: 100%;
}
.tenant-wbw .c-tabs-filter-listing .date-filter-wrapper {
  padding-top: 30px;
  border-top: none;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .date-filter-wrapper {
    padding-top: 0;
  }
}
.tenant-wbw .c-tabs-filter-listing .title {
  text-align: center;
  margin-top: 40px;
}
.tenant-wbw .c-tabs-filter-listing .total-count {
  text-align: center;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .total-count {
    padding-bottom: 0;
    padding-top: 10px;
  }
}
.tenant-wbw .c-tabs-filter-listing .load-more-cta {
  display: block;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font: normal normal 400 12px "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  /* Small devices (tablets, 768px and up) */
  cursor: pointer;
  position: relative;
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .tenant-wbw .c-tabs-filter-listing .load-more-cta {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .tenant-wbw .c-tabs-filter-listing .load-more-cta {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.tenant-wbw .c-tabs-filter-listing .load-more-cta button {
  padding: 0;
  border-style: none;
  line-height: 1.6;
  letter-spacing: 1.9px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta {
    font-size: 13px;
    letter-spacing: 0;
  }
}
.tenant-wbw .c-tabs-filter-listing .load-more-cta button {
  line-height: 14px;
  font-size: 12px;
  letter-spacing: 2px;
  color: #000000;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-wbw .c-tabs-filter-listing .load-more-cta button {
  letter-spacing: 0px;
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta button {
    font-size: 13px;
  }
}
.tenant-wbw .c-tabs-filter-listing .load-more-cta:after {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  content: "+";
  margin-left: 8px;
  font-family: initial;
  display: inline-block;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta:after {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta:after {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-tabs-filter-listing .load-more-cta:after {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.67;
  }
}
.tenant-wbw .c-tabs-filter-listing .load-more-cta:hover {
  text-decoration: underline;
}
.tenant-wbw .c-tabs-filter-listing .load-more-cta:hover:after {
  text-decoration: none;
}
.tenant-wbw .c-tabs-filter-listing .load-more-cta a {
  text-decoration: none;
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-top: 10px;
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
  padding: 0;
  border-style: none;
  line-height: 1.6;
  letter-spacing: 1.9px;
  text-transform: uppercase;
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container.reverse a:before,
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container.reverse button:before {
  border: solid 2px #fff;
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container .header-bottom a::before, .tenant-wbw .c-tabs-filter-listing .view-more-cta-container .header-bottom button::before {
  top: 8px;
  left: 8px;
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
  position: relative;
  -webkit-border-radius: 6px;
  -khtml-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  background-color: #ffdf00;
  color: #000;
  border: solid 2px #000;
  padding: 7px 20px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: center;
  margin: 0 9px 9px 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
.lang-ru .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
.lang-zh .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:before,
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:before {
  content: " ";
  position: absolute;
  top: 9px;
  left: 9px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 2px #000;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:hover,
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:hover {
    background-color: #fff;
    top: 3px;
    left: 3px;
    border: 2px solid #333;
  }
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:hover:before,
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:hover:before {
    background-color: #ffdf00;
    top: 6px;
    left: 6px;
  }
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:hover:after,
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:hover:after {
    content: "";
    position: absolute;
    top: -10px;
    right: 0;
    width: 100%;
    height: 20px;
  }
  .lang-zh .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a, .lang-ru .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
  .lang-zh .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button,
  .lang-ru .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
    padding: 10px 20px;
  }
}
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0;
  margin: 0 0 9px 9px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a,
  .lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button {
    letter-spacing: 0;
  }
}
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:before,
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:before {
  right: 9px;
}
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:focus,
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:focus {
  right: 0;
}
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:focus:before,
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:focus:before {
  right: 9px;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:hover,
  .lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:hover {
    right: 3px;
  }
  .lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:hover:before,
  .lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container button:hover:before {
    right: 6px;
  }
}
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container.c-annual-pass-add-cart, .lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container.button-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.lang-ar .tenant-wbw .c-tabs-filter-listing .view-more-cta-container .secondary-cta {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container a {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a {
    padding: 11px 20px;
  }
  .tenant-wbw .c-tabs-filter-listing .view-more-cta-container a:focus {
    padding: 11px 20px;
  }
}
.tenant-wbw .c-tabs-filter-listing .view-more-cta-container a.view-more-cta {
  outline: none;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded {
  text-align: center;
  background-color: #f6f6f6;
  margin: 15px 0 30px;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter {
  margin: 0 auto;
  padding-top: 20px;
  background-color: #fff;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter {
    padding-top: 30px;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter.show,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter.show {
  background-color: #f6f6f6;
  padding: 20px 0;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter.show,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter.show {
    padding: 30px 0;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter button,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter button {
  background: none;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 14px;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter button,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter button {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter button,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter button {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter button,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter button {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .show-filter button div.filter-icon,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .show-filter button div.filter-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 12px;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list {
  width: 95.2380952381%;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  background: #f6f6f6;
  position: relative;
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list {
    width: 64.8484848485%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .c-facet-filter,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .c-facet-filter {
  margin-bottom: 0;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .total-count,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .total-count {
  margin-bottom: 40px;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list ul.facets,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list ul.facets {
  list-style-type: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list ul.facets,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list ul.facets {
    margin: 0 auto;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-container,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-container {
  width: 85%;
  margin: 0 auto 43px;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-container,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-container {
    margin-bottom: 60px;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .reset-btn,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .reset-btn {
  background: none;
  padding: 33px 0 33px 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .reset-btn,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .reset-btn {
    margin: 0 0 40px 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .reset-btn,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .reset-btn {
    padding: 0 4px 0 0;
    margin-bottom: 40px;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .reset-btn div.reset-icon,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .reset-btn div.reset-icon {
  width: 10px;
  height: 11px;
  display: inline-block;
  margin-left: 4px;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-list,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-list {
  margin: 30px 8px 0 8px;
  line-height: 44px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-list,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-list {
    margin: 0 30px 36px 0;
    padding-bottom: 4px;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-list input,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-list input {
  display: none;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-list label,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-list label {
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 100px;
  padding: 13px 24px;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-list label,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-list label {
    padding: 13px 50px;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-list.active label,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-list.active label {
  background: #ffdf00;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-label,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-label {
  display: inline-block;
  padding-bottom: 21px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-label,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-label {
    padding-bottom: 27px;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip {
  display: inline-block;
  position: relative;
  margin-left: 9px;
  vertical-align: middle;
  cursor: pointer;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip-icon,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip-icon {
  background-color: #f6f6f6;
  border-radius: 50%;
  border: 1px solid #333;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  text-decoration: none;
  width: 12px;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip-body,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip-body {
  background-color: #fff;
  border: solid 1px #d5d5d5;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 22px 30px;
  position: absolute;
  top: 20px;
  transform: translate(-68.6%, 13%);
  width: 268px;
  z-index: 12;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip-body,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip-body {
    width: 280px;
    transform: translate(-48.5%, 13%);
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip-body:before,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip-body:before {
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-width: 1px 0 0 1px;
  content: "";
  display: block;
  height: 10px;
  left: 70.1%;
  position: absolute;
  top: -6px;
  transform: rotateZ(45deg);
  width: 10px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip-body:before,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip-body:before {
    left: 50%;
  }
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip-body .short-title,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip-body .short-title {
  text-align: center;
  float: none;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .slider-tooltip svg,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .slider-tooltip svg {
  width: 18px;
  height: 18px;
}
.tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-title p,
.tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-title p {
  font-weight: bold;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .c-list-filter .filter-list .facet-title p,
  .tenant-wbw .c-tabs-filter-listing .c-list-filter.filter-expanded .filter-list .facet-title p {
    padding-bottom: 30px;
  }
}
.tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom {
  padding-top: 40px;
}
.tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .heading-5 {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.22;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .heading-5 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .heading-5 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .heading-5 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .body-copy-4 {
  line-height: 1.7;
  letter-spacing: 0.1px;
}
.tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .readMoreCTA .feature-tile--cta {
  font: normal normal 400 12px "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  /* Small devices (tablets, 768px and up) */
  cursor: pointer;
}
.tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .readMoreCTA .feature-tile--cta button {
  padding: 0;
  border-style: none;
  line-height: 1.6;
  letter-spacing: 1.9px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .readMoreCTA .feature-tile--cta {
    font-size: 13px;
    letter-spacing: 0;
  }
}
.tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .listingIconContainer {
  display: table;
  width: 100%;
}
.tenant-wbw .c-tabs-filter-listing .component.c-tab-feature-list .feature-tile-bottom .listingIconContainer div.icon-data:nth-child(1n) {
  display: table-cell;
}
.tenant-wbw .c-tabs-filter-listing.v-fix-cta-to-bottom .c-feature-grid .feature-tile-wrapper div.feature-tile-bottom {
  padding-bottom: 37px;
}
.tenant-wbw .c-tabs-filter-listing.v-fix-cta-to-bottom .c-feature-grid .feature-tile-wrapper div.readMoreCTA {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing.v-fix-cta-to-bottom .c-feature-grid .feature-tile-wrapper div.readMoreCTA {
    width: auto;
    left: auto;
  }
}
.tenant-wbw .c-tabs-filter-listing.v-fix-cta-to-bottom .c-feature-grid .feature-tile-wrapper div.readMoreCTA a,
.tenant-wbw .c-tabs-filter-listing.v-fix-cta-to-bottom .c-feature-grid .feature-tile-wrapper div.readMoreCTA .feature-tile--cta {
  margin-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-tabs-filter-listing.v-fix-cta-to-bottom .c-feature-grid .feature-tile-wrapper div.readMoreCTA a,
  .tenant-wbw .c-tabs-filter-listing.v-fix-cta-to-bottom .c-feature-grid .feature-tile-wrapper div.readMoreCTA .feature-tile--cta {
    padding-right: 20px;
  }
}
.tenant-wbw .c-tabs-filter-listing.v-carousel-mobile-view {
  max-width: 100%;
}
.tenant-wbw .date-filter-wrapper {
  padding-top: 60px;
  border-top: 1px solid #f2f2f2;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-wrapper {
    border-top: none;
    padding-top: 0;
    margin-bottom: 40px;
  }
}
.tenant-wbw .date-filter-title {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .date-filter-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .date-filter-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .tenant-wbw .date-filter-title {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .tenant-wbw .date-filter-title {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.tenant-wbw .date-filter-fields-wrapper {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-fields-wrapper {
    display: inline-block;
  }
}
.tenant-wbw .date-filter-submit-button-wrapper {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-submit-button-wrapper {
    display: inline-block;
  }
}
.tenant-wbw .date-filter-field {
  display: block;
  margin: 0 0 20px;
  position: relative;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-field {
    display: inline-block;
    margin: 0 40px 0 0;
  }
}
.tenant-wbw .date-filter-field-label {
  display: block;
  margin-bottom: 10px;
}
.tenant-wbw .date-filter-error-msg {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #d0021b;
  margin-bottom: 10px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .date-filter-error-msg {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 24px;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-error-msg {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .date-filter-error-msg {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .date-filter-error-msg {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-error-msg {
    position: absolute;
    right: 0;
    left: 0;
  }
}
.tenant-wbw .date-filter-error-msg.error-msg-hide {
  display: none;
  background-color: #d0021b;
}
.tenant-wbw .date-filter-submit-button {
  position: relative;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 14px;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 100px;
  padding: 13px 66px 13px 50px;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
}
.tenant-wbw .date-filter-submit-button::after {
  content: "";
  border: 1px solid #333;
  border-bottom-width: 0;
  border-left-width: 0;
  position: absolute;
  width: 6px;
  height: 6px;
  top: 15px;
  -webkit-transform: rotate(135deg);
  -khtml-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 36px;
}
@media (min-width: 768px) {
  .tenant-wbw .date-filter-submit-button {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .date-filter-submit-button {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .date-filter-submit-button {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.tenant-wbw .c-tabs-with-placeholder .tabs-panel-container .c-tabs-filter-listing .tabs-panel-container {
  width: 100%;
}

/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .filter-icon {
  margin-left: 0px;
  margin-right: 12px;
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .slider-tooltip {
  margin-left: 0px;
  margin-right: 9px;
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .slider-tooltip .slider-tooltip-body {
  transform: translateX(70%);
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .slider-tooltip .slider-tooltip-body:before {
  left: 25%;
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .slider-tooltip a {
  direction: ltr;
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .reset-icon {
  margin-left: 0px;
  margin-right: 4px;
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .tickList {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .slider-tooltip-body {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .range-container {
  direction: ltr;
  transform: rotateY(180deg);
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .ticks {
  transform: rotateY(180deg);
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .range-tooltip {
  transform: rotateY(-180deg);
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .filter-list .facet-list {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .c-list-filter .filter-list .facet-list {
    margin: 0 30px 34px 0;
    padding-bottom: 6px;
    padding-top: 5px;
  }
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}
.lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta:after {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta:after {
    font-size: 16px;
    font-size: 1rem;
    line-height: 28px;
    line-height: 1.75rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta:after {
    font-size: 16px;
    font-size: 1rem;
    line-height: 18px;
    line-height: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta:after {
    font-size: 16px;
    font-size: 1rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-wbw .component.c-tabs-filter-listing .load-more-cta:after {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
}